import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import TextField, { HelperText, Input } from '@material/react-text-field'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import isEmail from 'validator/lib/isEmail'
import normalizeEmail from 'validator/lib/normalizeEmail'

const GET_TEACHER = gql`
  query Teacher($email: String!) {
    teacher(email: $email) {
      id
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`

const ShareNew = ({ onDoNotShare, onSubmit }) => {
  const [email, setEmail] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [teacherDoesNotExist, setTeacherDoesNotExist] = useState(false)

  const renderHelperText = () => {
    if (emailIsValid && !teacherDoesNotExist)
      return null

    const text = teacherDoesNotExist ? 'Teacher with this Email does not exist' : 'Please enter a valid email address'
    return <HelperText validation persistent>{text}</HelperText>
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter')
      handleSubmit()
  }

  const handleSubmit = () => {
    setEmailIsValid(true)
    setTeacherDoesNotExist(false)

    if (!isEmail(email)) {
      setEmailIsValid(false)
      return
    }

    // verify teacher exists
    getTeacher({ variables: { email: normalizeEmail(email) } })
  }

  const handleQueryCompleted = (data) => {
    if (!data.teacher) {
      setTeacherDoesNotExist(true)
      return
    }

    onSubmit(data.teacher)
  }

  const [getTeacher, { loading }] = useLazyQuery(GET_TEACHER, { onCompleted: handleQueryCompleted })

  return (
    <div className='ShareNew'>
      <p className='bottom-32'>
        If you'd like a teacher to be able to access your writing today, enter their email below.
      </p>

      <div className='form-wrapper'>
        <div className='form-field'>
          <TextField
            label='Teacher Email'
            helperText={renderHelperText()}
            outlined
          >
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              isValid={emailIsValid && !teacherDoesNotExist}
              disabled={loading}
            />
          </TextField>
        </div>
        <div className='btns'>
          <Button
            className='btn-filled-gray'
            onClick={onDoNotShare}
            disabled={loading}
            unelevated
          >
            No Thanks
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            unelevated
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

ShareNew.propTypes = {
  onDoNotShare: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ShareNew