import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'

import DraftListItem from './DraftListItem'
import { useForceUpdate, useInterval } from '../../hooks'

const DraftList = ({ drafts, onMarkClick, onUploadClick }) => {
  const forceUpdate = useForceUpdate()
  useInterval(forceUpdate, 60000) // rerender every minute to update expiresIn values in DraftListItem

  if (drafts.length === 0)
    return null

  return (
    <div className='DraftList list'>
      <div className='row header'>
        <div className='col-2' />
        <div>Name</div>
        <div className='col-2'>Draft #</div>
        <div className='col-2'>Expires In</div>
        <Button className='hidden'>Download</Button>
        <Button className='hidden'>Upload</Button>
      </div>

      {drafts.map(draft =>
        <DraftListItem
          draft={draft}
          onMarkClick={onMarkClick}
          onUploadClick={onUploadClick}
          key={draft.id}
        />
      )}
    </div>
  )
}

DraftList.propTypes = {
  drafts: PropTypes.array.isRequired,
  onMarkClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired
}

export default DraftList