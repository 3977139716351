import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'

const ConfirmTeacher = ({ teacher, onCancel, onConfirm }) => {
  const { user: { email,  firstName, lastName } } = teacher

  return (
    <div className='ConfirmTeacher'>
      <p className='bottom-32'>
        You have selected to share your writing with&nbsp;
        <span className='standout'>{firstName} {lastName} ({email})</span>. Do you wish to continue?
      </p>
      <div className='btns'>
        <Button className='btn-filled-gray' onClick={onCancel} unelevated>
          Go Back
        </Button>
        <Button onClick={onConfirm} unelevated>
          Continue
        </Button>
      </div>
    </div>
  )
}

ConfirmTeacher.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired
}

export default ConfirmTeacher