import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material/react-linear-progress'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import { DraftsViewer, MainLayout, ShareDraft, WelcomeHeader } from '../components'
import { alreadyWroteToday } from '../lib/util'
import './Home.css'

const QUERY = gql`
  {
    currentPrompt {
      id
    }
    mostRecentDraft {
      id
      created
    }
  }
`

const VIEWS = {
  alreadyWroteToday: 'alreadyWroteToday',
  noPrompt: 'noPrompt',
  shareDraft: 'shareDraft'
}

const HomeView = ({ history }) => {
  const { loading, error, data } = useQuery(QUERY)
  let view

  if (!loading && !error) {
    if (data.currentPrompt === null)
      view = VIEWS.noPrompt
    else if (alreadyWroteToday(data.mostRecentDraft))
      view = VIEWS.alreadyWroteToday
    else
      view = VIEWS.shareDraft
  }

  return (
    <MainLayout>
      <div className='Home width-700'>
        <WelcomeHeader />

        {loading &&
          <LinearProgress indeterminate />
        }

        {error &&
          <p className='error'>Failed to fetch data</p>
        }

        {view &&
          <>
            {view === VIEWS.noPrompt &&
              <p>
                There is no prompt at this time. Check back again later.
              </p>
            }

            {view === VIEWS.alreadyWroteToday &&
              <>
                <p className='bottom-32'>
                  It looks like you've already written today. Come back tomorrow!
                </p>

                <DraftsViewer />
              </>
            }

            {view === VIEWS.shareDraft &&
              <ShareDraft onSelectTeacher={() => history.push('/draft/')} />
            }
          </>
        }
      </div>
    </MainLayout>
  )
}

HomeView.propTypes = {
  history: PropTypes.object.isRequired
}

export default HomeView