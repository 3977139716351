import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import Dialog, { DialogButton, DialogContent, DialogFooter } from '@material/react-dialog'
import LinearProgress from '@material/react-linear-progress'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Timer from '../Timer'
import { useInterval } from '../../hooks'
import { wordCount } from '../../lib/util'
import './Editor.scss'

const WRITING_SESSION_FRAGMENT = gql`
  fragment writingSessionFields on WritingSessionType {
    id
    text
    timeRemaining
  }
`

const MY_WRITING_SESSION = gql`
  {
    myWritingSession {
      ...writingSessionFields
    }
  }
  ${WRITING_SESSION_FRAGMENT}
`

const SAVE_WRITING_SESSION = gql`
  mutation SaveWritingSession($input: WritingSessionInput!) {
    saveWritingSession(input: $input) {
      writingSession {
        ...writingSessionFields
      }
    }
  }
  ${WRITING_SESSION_FRAGMENT}
`

const Editor = ({ disabled, initialText, noTimer, onSubmit, prompt }) => {
  const [confirmSubmitIsOpen, setConfirmSubmitIsOpen] = useState(false)
  const [outOfTimeIsOpen, setOutOfTimeIsOpen] = useState(false)
  const [text, setText] = useState(initialText)
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [saveWritingSession] = useMutation(SAVE_WRITING_SESSION)

  const saveProgress = useCallback(() => {
    saveWritingSession({ variables: { input: { text, timeRemaining } } })
  }, [saveWritingSession, text, timeRemaining])

  useEffect(() => {
    window.onbeforeunload = (e) => {
      delete e.returnValue // don't show popup
      saveProgress()
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [saveProgress])

  useInterval(() => {
    if (timeRemaining > 0)
      saveProgress()
  }, 15000)

  const handleCloseDialog = (action) => {
    setConfirmSubmitIsOpen(false)

    if (action === 'submit') {
      onSubmit(text)
    }
  }

  const { loading, data } = useQuery(MY_WRITING_SESSION, {
    variables: {},
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.myWritingSession)
        setText(data.myWritingSession.text)
    }
  })

  if (loading)
    return <LinearProgress indeterminate />

  const timerSeconds = (data && data.myWritingSession && data.myWritingSession.timeRemaining) || prompt.responseTime

  return (
    <div className='Editor'>
      <div className='top-group'>
        <p className='prompt-text'>
          {prompt.text}
        </p>
        <div className='time-remaining'>
          {noTimer ?
            <div>--</div> :
            <Timer
              seconds={timerSeconds}
              pause={disabled}
              onEnd={() => setOutOfTimeIsOpen(true)}
              onTick={tr => setTimeRemaining(tr)}
            />
          }
        </div>
      </div>
      <div className='middle-group'>
        <textarea
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder='Write your response here'
          disabled={disabled}
        />
      </div>
      <div className='bottom-group'>
        <div className='word-count-container'>
          Word Count: <span className='word-count'>{wordCount(text)}</span>
        </div>
        <Button
          onClick={() => setConfirmSubmitIsOpen(true)}
          disabled={disabled}
          unelevated
        >
          Submit
        </Button>
      </div>

      <Dialog open={confirmSubmitIsOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <p>Are you sure you want to submit your writing?</p>
        </DialogContent>
        <DialogFooter>
          <DialogButton action='cancel'>Cancel</DialogButton>
          <DialogButton action='submit'>Submit</DialogButton>
        </DialogFooter>
      </Dialog>

      <Dialog open={outOfTimeIsOpen} escapeKeyAction='' scrimClickAction=''>
        <DialogContent>
          <p>
            Time has run out. Your writing will be submitted in
          </p>
          <div className='centered'>
            <Timer
              seconds={5}
              pause={!outOfTimeIsOpen}
              onEnd={() => onSubmit(text)}
              showSecondsOnly
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

Editor.defaultProps = {
  disabled: false,
  initialText: '',
  noTimer: false
}

Editor.propTypes = {
  disabled: PropTypes.bool,
  initialText: PropTypes.string,
  noTimer: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  prompt: PropTypes.object.isRequired
}

export default Editor