import moment from 'moment'

/**
 * Get a 2D array representing the given year and month
 * @param year, 4 digits
 * @param month, 0-11
 * @returns {Array}
 */
export const getMonthArray = (year, month) => {
  const addDayToWeek = (day, prepend = false) => {
    const dayInfo = {
      day: day.date(),
      month: day.month(),
      year: day.year()
    }

    if (prepend)
      weekArray.splice(0, 0, dayInfo)
    else
      weekArray.push(dayInfo)
  }

  const firstDayOfMonth = moment({ year, month }).startOf('month')
  const lastDayOfMonth = moment({ year, month }).endOf('month')
  const monthArray = []
  let weekArray = []
  let day = moment(firstDayOfMonth)

  while (day.weekday() !== 0) {
    // add dates from previous month but same week
    day.subtract(1, 'day')
    addDayToWeek(day, true)
  }

  day = moment(firstDayOfMonth)
  let foundLastDayOfMonth = false

  while (true) {
    addDayToWeek(day)

    if (day.isSame(lastDayOfMonth, 'day'))
      foundLastDayOfMonth = true

    if (day.weekday() === 6) {
      monthArray.push(weekArray)
      weekArray = []

      if (foundLastDayOfMonth)
        break
    }

    day.add(1, 'day')
  }

  return monthArray
}