import React, { useContext, useEffect, useState } from 'react'
import ApolloClient, { InMemoryCache } from 'apollo-boost'
import LinearProgress from '@material/react-linear-progress'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import AdminView from './admin'
import AuthRouter from './auth'
import DraftRouter from './draft'
import ErrorBoundary from './ErrorBoundary'
import HomeView from './home'
import TeacherView from './teacher'
import { CsrfTokenContext } from './CsrfTokenProvider'
import { NotFound } from './components'
import { adminRequired, loginRequired, teacherRequired } from './auth/decorators'
import './styles.scss'

const App = () => {
  const [ready, setReady] = useState(false)
  const { csrfToken, updateCsrfToken } = useContext(CsrfTokenContext)

  useEffect(() => {
    const getCsrfToken = async () => {
      await fetch('/graphql')
      setReady(true)
      updateCsrfToken()
      console.log('Fetched /graphql to set csrftoken cookie')
    }

    if (csrfToken)
      setReady(true)
    else if (process.env.NODE_ENV === 'development')
      getCsrfToken()

    // eslint-disable-next-line
  }, [])

  if (!ready)
    return <LinearProgress indeterminate />

  const cache = new InMemoryCache()
  const client = new ApolloClient({
    cache,
    headers: {
      'X-CSRFToken': csrfToken
    },
    resolvers: {}
  })

  cache.writeData({
    data: {
      shareDraftTeacherId: null
    }
  })

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ErrorBoundary>
          <Switch>
            <Route exact strict path='/' component={AuthRouter} />
            <Route path='/auth/' component={AuthRouter} />
            <Route path='/draft/' component={loginRequired(DraftRouter)} />
            <Route exact strict path='/admin/' component={adminRequired(AdminView)} />
            <Route exact strict path='/home/' component={loginRequired(HomeView)} />
            <Route exact strict path='/teacher/' component={teacherRequired(TeacherView)} />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
