import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import LinearProgress from '@material/react-linear-progress'
import classNames from 'classnames'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import { Info } from '../components'
import { getPrivileges } from '../lib/util'
import './EditUserDialog.scss'

const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
      isSuperuser
      teacher {
        id
        canBeDeleted
      }
    }
  }
`

const CREATE_TEACHER = gql`
  mutation CreateTeacher($input: CreateTeacherInput!) {
    createTeacher(input: $input) {
      user {
        id
        teacher {
          id
          canBeDeleted
        }
      }
    }
  }
`

const DELETE_TEACHER = gql`
  mutation DeleteTeacher($input: DeleteTeacherInput!) {
    deleteTeacher(input: $input) {
      user {
        id
        teacher {
          id
        }
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        isSuperuser
      }
    }
  }
`

const EditUserDialog = ({ onClose, open, userId }) => {
  const [snackbarText, setSnackbarText] = useState(null)
  const [getUser, { loading: queryLoading, data }] = useLazyQuery(USER)
  const user = data ? data.user : null

  const [createTeacher, { loading: createTeacherLoading }] = useMutation(CREATE_TEACHER, {
    onError: () => {
      setSnackbarText(`Failed to make ${user.name} a teacher`)
    }
  })

  const [deleteTeacher, { loading: deleteTeacherLoading }] = useMutation(DELETE_TEACHER, {
    onError: () => {
      setSnackbarText(`Failed to remove ${user.name} as a teacher`)
    }
  })

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onError: () => {
      if (user.isSuperuser)
        setSnackbarText(`Failed to remove ${user.name} as an admin`)
      else
        setSnackbarText(`Failed to make ${user.name} an admin`)
    }
  })

  useEffect(() => {
    if (userId !== null) {
      getUser({ variables: { id: userId } })
    }
  }, [getUser, userId])

  const handleMakeTeacherClick = () => {
    setSnackbarText(null)
    createTeacher({
      variables: {
        input: {
          userId: user.id
        }
      }
    })
  }

  const handleRemoveTeacherClick = () => {
    setSnackbarText(null)
    deleteTeacher({
      variables: {
        input: {
          teacherId: user.teacher.id
        }
      }
    })
  }

  const toggleAdminStatus = () => {
    setSnackbarText(null)
    updateUser({
      variables: {
        input: {
          userId: user.id,
          isSuperuser: !user.isSuperuser
        }
      }
    })
  }

  const disabled = createTeacherLoading || deleteTeacherLoading || updateUserLoading
  const closeAction = disabled ? '' : 'close'

  return (
    <div className='EditUserDialog'>
      <Dialog
        open={open}
        onClose={onClose}
        escapeKeyAction={closeAction}
        scrimClickAction={closeAction}
      >
        <DialogTitle>
          Edit User
        </DialogTitle>

        <DialogContent>
          {queryLoading &&
            <LinearProgress indeterminate />
          }

          {user &&
            <>
              <div className='list bottom-48'>
                <div className='row header'>
                  <div>Name</div>
                  <div>Email</div>
                  <div>Privileges</div>
                </div>
                <div className='row'>
                  <div>{user.name}</div>
                  <div>{user.email}</div>
                  <div>{getPrivileges(user)}</div>
                </div>
              </div>

              <div className='btns-inline bottom-32'>
                <Button
                  className={classNames({
                    'btn-filled-error': user.isSuperuser,
                    'btn-filled-secondary': !user.isSuperuser
                  })}
                  onClick={toggleAdminStatus}
                  disabled={disabled}
                  unelevated
                >
                  {user.isSuperuser ? 'Remove' : 'Make'} Admin
                </Button>

                {user.teacher === null &&
                  <Button
                    className='btn-filled-secondary'
                    onClick={handleMakeTeacherClick}
                    disabled={disabled}
                    unelevated
                  >
                    Make Teacher
                  </Button>
                }

                {user.teacher && user.teacher.canBeDeleted &&
                  <Button
                    className='btn-filled-error'
                    onClick={handleRemoveTeacherClick}
                    disabled={disabled}
                    unelevated
                  >
                    Remove Teacher
                  </Button>
                }
              </div>

              {user.teacher && !user.teacher.canBeDeleted &&
                <Info>
                  Teacher privilege cannot be removed since this user has had drafts shared with them
                </Info>
              }
            </>
          }
        </DialogContent>

        <DialogFooter>
          <DialogButton action='done' disabled={disabled} isDefault>
            Done
          </DialogButton>
        </DialogFooter>
      </Dialog>

      {snackbarText &&
        <Snackbar
          message={snackbarText}
          actionText='Dismiss'
          onClose={() => setSnackbarText(null)}
          timeoutMS={5000}
        />
      }
    </div>
  )
}

EditUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string
}

export default EditUserDialog