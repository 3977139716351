import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import classNames from 'classnames'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Editor, MainLayout, PDFViewer } from '../components'
import './RewriteView.scss'

const MOST_RECENT_DRAFT = gql`
  {
    mostRecentDraft {
      id
      draftfeedback {
        id
        file
        draftrewrite {
          id
        }
      }
      promptInstance {
        id
        prompt {
          id
          text
        }
      }
    }
  }
`

const CREATE_DRAFT_REWRITE = gql`
  mutation CreateDraftRewrite($input: DraftRewriteInput!) {
    createDraftRewrite(input: $input) {
      draftRewrite {
        id
        text
      }
    }
  }
`

const VIEWS = {
  ready: 'ready',
  rewrite: 'rewrite',
  submitted: 'submitted'
}

const RewriteView = ({ history }) => {
  const [view, setView] = useState(null)

  const handleContinue = () => {
    history.push('/draft/write/')
  }

  const handleSubmit = (text) => {
    createDraftRewrite({
      variables: {
        input: {
          draftFeedbackId: mostRecentDraft.draftfeedback.id,
          text
        }
      }
    })
  }

  const { loading, error, data, refetch } = useQuery(MOST_RECENT_DRAFT, {
    onCompleted: (data) => {
      if (!data.mostRecentDraft || !data.mostRecentDraft.draftfeedback)
        history.replace('/draft/')
      else if (data.mostRecentDraft.draftfeedback.draftrewrite)
        setView(VIEWS.submitted)
      else if (view === null)
        setView(VIEWS.ready)
    }
  })

  const [createDraftRewrite, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_DRAFT_REWRITE, {
    onCompleted: () => refetch()
  })

  const { mostRecentDraft } = data
  let file, prompt

  if (mostRecentDraft && mostRecentDraft.draftfeedback) {
    file = mostRecentDraft.draftfeedback.file
    prompt = mostRecentDraft.promptInstance.prompt
  }

  return (
    <MainLayout className='RewriteView'>
      {loading &&
        <LinearProgress className='width-700' indeterminate />
      }

      {error &&
        <p className='error width-700'>Failed to load feedback</p>
      }

      {view === VIEWS.ready &&
        <div className='width-700'>
          <h1 className='page-header'>
            Rewrite Draft
          </h1>
          <p className='bottom-32'>
            Before you start writing, it looks like you have some feedback to look at first. Retype your response
            and correct the errors that are marked. There is no time limit.
          </p>
          <div className='right'>
            <Button onClick={() => setView(VIEWS.rewrite)} unelevated>
              Continue
            </Button>
          </div>
        </div>
      }

      {view === VIEWS.rewrite &&
        <div className={classNames({ 'side-by-side': file, 'width-900': !file, 'width-1300': file })}>
          {file &&
            <PDFViewer file={file} />
          }

          <Editor
            prompt={prompt}
            disabled={mutationLoading}
            noTimer
            onSubmit={handleSubmit}
          />
        </div>
      }

      {view === VIEWS.submitted &&
        <div className='width-700'>
          <h1 className='page-header'>
            Rewrite Submitted
          </h1>
          <p className='bottom-32'>
            Your rewrite has successfully been submitted. You may now continue with writing your next response
            to this week's prompt.
          </p>
          <div className='right'>
            <Button onClick={handleContinue} unelevated>
              Continue
            </Button>
          </div>
        </div>
      }

      {mutationError &&
        <Snackbar
          message='Failed to submit rewrite'
          actionText='Dismiss'
          timeoutMS={5000}
        />
      }
    </MainLayout>
  )
}

RewriteView.propTypes = {
  history: PropTypes.object.isRequired
}

export default RewriteView