import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import LinearProgress from '@material/react-linear-progress'
import { Snackbar } from '@material/react-snackbar'
import axios from 'axios'

import './UploadDialog.scss'

const UploadDialog = ({ draft, onClose, open }) => {
  const [canClose, setCanClose] = useState(true)
  const [minUploadTimeCompleted, setMinUploadTimeCompleted] = useState(true)
  const [snackbarText, setSnackbarText] = useState(null)
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(true)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState('ready')

  const fileInput = useRef(null)
  const closeAction = canClose ? 'close' : ''
  const maxFileSize = 5 * 1024 * 1024 // 5MB
  const minUploadTime = 500 // 0.5 seconds

  useEffect(() => {
    // The purpose of minUploadTime is to provide a better user experience. Small files will upload quickly enough that
    // the upload progress bar will not even display, creating an unpleasant "flashing" of the dialog. This effect
    // will detect when an upload is complete, ensuring minUploadTime has elapsed first.

    const handleUploadCompleted = () => {
      setCanClose(true)
      setUploadBtnDisabled(false)

      if (uploadStatus === 'success') {
        onClose('uploadSuccess')
        setSnackbarText('Successfully uploaded file')
        setUploadProgress(0)
        setUploadStatus('ready')
      } else {
        setSnackbarText('Failed to upload file')
      }
    }

    if (minUploadTimeCompleted && (uploadStatus === 'error' || uploadStatus === 'success')) {
      handleUploadCompleted()
    }
  }, [minUploadTimeCompleted, onClose, uploadStatus])

  const getName = () => {
    return draft ? `${draft.user.firstName} ${draft.user.lastName}` : ''
  }

  const handleClose = (action) => {
    onClose(action)
    setUploadBtnDisabled(true)
    setUploadProgress(0)
    setUploadStatus('ready')
    fileInput.current.value = ''
  }

  const handleFileChange = () => {
    const files = fileInput.current.files
    let disabled = true

    if (files.length > 0) {
      if (files[0].size > maxFileSize) {
        setSnackbarText('The selected file is too large')
      } else {
        disabled = false
      }
    }

    setUploadBtnDisabled(disabled)
    setUploadProgress(0)
    setUploadStatus('ready')
  }

  const uploadFile = async () => {
    setCanClose(false)
    setMinUploadTimeCompleted(false)
    setSnackbarText(null)
    setUploadBtnDisabled(true)
    setUploadProgress(0)
    setUploadStatus('uploading')

    let response
    const form = new FormData()
    form.append('draft', draft.id)
    form.append('file', fileInput.current.files[0])

    setTimeout(() => setMinUploadTimeCompleted(true), minUploadTime)

    try {
      response = await axios.post('/upload/feedback/', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        onUploadProgress: (progressEvent) => {
          setUploadProgress(progressEvent.loaded / progressEvent.total)
        }
      })
    } catch (e) {
      setUploadStatus('error')
    }

    if (response && response.status === 200)
      setUploadStatus('success')
    else
      setUploadStatus('error')
  }

  return (
    <div className='UploadDialog'>
      <Dialog open={open} onClose={handleClose} escapeKeyAction={closeAction} scrimClickAction={closeAction}>
        <DialogTitle>
          Upload Feedback
        </DialogTitle>

        <DialogContent>
          <p>
            Choose a file to upload feedback for&nbsp;
            <span className='standout'>{getName()}</span>
          </p>

          <div className='form-field'>
            <input
              type='file'
              ref={fileInput}
              accept='application/pdf'
              onChange={handleFileChange}
              disabled={!canClose}
              required
            />
            <label>
              Max file size: 5MB
            </label>
          </div>

          {uploadStatus !== 'ready' &&
            <LinearProgress className='bottom-32' progress={uploadProgress} />
          }
        </DialogContent>

        <DialogFooter>
          <DialogButton action='cancel' disabled={!canClose}>
            Cancel
          </DialogButton>
          <DialogButton action='' onClick={uploadFile} disabled={uploadBtnDisabled}>
            Upload
          </DialogButton>
        </DialogFooter>
      </Dialog>

      {snackbarText &&
        <Snackbar
          message={snackbarText}
          actionText='Dismiss'
          timeoutMS={5000}
          onClose={() => setSnackbarText(null)}
        />
      }
    </div>
  )
}

UploadDialog.propTypes = {
  draft: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default UploadDialog