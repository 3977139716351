import React from 'react'
import classNames from 'classnames'

import AppHeader from './AppHeader'

const MainLayout = ({ children, className, showCenteredHeader }) => {
  return (
    <div className='MainLayout'>
      {!showCenteredHeader &&
        <AppHeader />
      }

      <main className={classNames(className)}>
        {showCenteredHeader &&
          <h1 className='landing-page-header'>
            Daily TOEFL Writer
          </h1>
        }

        {children}
      </main>
    </div>
  )
}

export default MainLayout