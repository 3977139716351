import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import TextField, { HelperText, Input } from '@material/react-text-field'
import moment from 'moment'
import normalizeEmail from 'validator/lib/normalizeEmail'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import { CsrfTokenContext } from '../CsrfTokenProvider'
import { MainLayout } from '../components'

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserMutationInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
      }
      errors {
        field
        messages
      }
    }
  }
`

const RegisterView = ({ history, location }) => {
  const emailFromLogin = location.state && location.state.email
  const [inputs, setInputs] = useState({ email: emailFromLogin || '' })
  const [errors, setErrors] = useState({})
  const { updateCsrfToken } = useContext(CsrfTokenContext)

  const addError = (key, message) => {
    setErrors(e => ({ ...e, [key]: message }))
  }

  const renderHelperText = (message) => {
    return message ? <HelperText validation persistent>{message}</HelperText> : null
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setInputs({ ...inputs, [id]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors({})

    if (inputs.password !== inputs.confirmPassword) {
      addError('confirmPassword', 'Passwords do not match')
      return
    }

    const inputData = { ...inputs }
    delete inputData.confirmPassword
    inputData.email = normalizeEmail(inputData.email)

    createUser({ variables: { input: inputData } })
  }

  const handleMutationCompleted = (data) => {
    if (data.createUser.errors.length > 0) {
      addError('registration', 'Failed to register new user')

      data.createUser.errors.forEach(({ field, messages }) => {
        addError(field, messages[0])
      })

      return
    }

    // registering logs a user in and sets a new CSRF token, which needs to be updated in the Apollo Client
    updateCsrfToken()
    history.replace('/home/')
  }

  const handleMutationError = () => {
    addError('registration', 'Failed to register new user')
  }

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: handleMutationCompleted,
    onError: handleMutationError
  })

  return (
    <MainLayout showCenteredHeader>
      <p className='bottom-32 centered'>
        Looks like you're new! Welcome! Please fill out the fields below to register.
      </p>

      <form className='width-400' onSubmit={handleSubmit}>
        <div className='form-field'>
          <TextField
            label='Email'
            helperText={renderHelperText(errors.email)}
            outlined
          >
            <Input
              id='email'
              type='email'
              value={inputs.email}
              onChange={handleInputChange}
              isValid={!errors.email}
              disabled={loading}
              required
              autoFocus
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField label='First Name' outlined>
            <Input
              id='firstName'
              value={inputs.firstName}
              onChange={handleInputChange}
              maxLength={50}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField label='Last Name' outlined>
            <Input
              id='lastName'
              value={inputs.lastName}
              onChange={handleInputChange}
              maxLength={50}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField label='Native Country' outlined>
            <Input
              id='nativeCountry'
              value={inputs.nativeCountry}
              onChange={handleInputChange}
              maxLength={60}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField label='Native Language' outlined>
            <Input
              id='nativeLanguage'
              value={inputs.nativeLanguage}
              onChange={handleInputChange}
              maxLength={50}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField
            label='Year of Birth'
            helperText={renderHelperText(errors.birthYear)}
            outlined
          >
            <Input
              id='birthYear'
              type='number'
              value={inputs.birthYear}
              onChange={handleInputChange}
              isValid={!errors.birthYear}
              min={1900}
              max={moment().year()}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField label='Password' outlined>
            <Input
              id='password'
              type='password'
              value={inputs.password}
              onChange={handleInputChange}
              minLength={8}
              maxLength={50}
              disabled={loading}
              required
            />
          </TextField>
        </div>
        <div className='form-field'>
          <TextField
            label='Confirm Password'
            helperText={renderHelperText(errors.confirmPassword)}
            outlined
          >
            <Input
              id='confirmPassword'
              type='password'
              value={inputs.confirmPassword}
              onChange={handleInputChange}
              isValid={!errors.confirmPassword}
              minLength={8}
              maxLength={50}
              disabled={loading}
              required
            />
          </TextField>
        </div>

        <div className='centered'>
          <Button unelevated>
            Submit
          </Button>
        </div>
      </form>

      {errors.registration &&
        <Snackbar
          message={errors.registration}
          actionText='Dismiss'
          timeoutMs={5000}
        />
      }
    </MainLayout>
  )
}

RegisterView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default RegisterView