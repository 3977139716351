import React from 'react'
import LinearProgress from '@material/react-linear-progress'
import { useQuery } from '@apollo/react-hooks'

import { MY_USER } from '../graphql/queries'

const isTeacher = (user) => user.teacher !== null

export const redirectIfLoggedIn = (Component) => (props) => {
  const { loading } = useQuery(MY_USER, {
    onCompleted: (data) => {
      if (data) {
        if (isTeacher(data.myUser))
          props.history.replace('/teacher/')
        else if (data.myUser.isSuperuser)
          props.history.replace('/admin/')
        else
          props.history.replace('/home/')
      }
    }
  })

  if (loading)
    return <LinearProgress indeterminate />

  return <Component {...props} />
}

const authRequired = (Component, isAuthorized) => (props) => {
  const { loading, error } = useQuery(MY_USER, {
    onCompleted: (data) => {
      if (isAuthorized && !isAuthorized(data)) {
        props.history.replace('/')
      }
    },

    onError: () => {
      props.history.replace('/')
    }
  })

  if (loading || error)
    return <LinearProgress indeterminate />

  return <Component {...props} />
}

export const adminRequired = (Component) => authRequired(Component, data => data.myUser.isSuperuser)

export const loginRequired = (Component) => authRequired(Component)

export const teacherRequired = (Component) => authRequired(Component, data => isTeacher(data.myUser))