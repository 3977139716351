import React from 'react'
import { Route, Switch } from 'react-router-dom'

import LandingPage from './LandingPage'
import LoginView from './LoginView'
import Logout from './Logout'
import RegisterView from './RegisterView'
import { NotFound } from '../components'
import { redirectIfLoggedIn } from './decorators'

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact strict path='/' component={redirectIfLoggedIn(LandingPage)} />
      <Route exact strict path='/auth/login/' component={redirectIfLoggedIn(LoginView)} />
      <Route exact strict path='/auth/logout/' component={Logout} />
      <Route exact strict path='/auth/register/' component={redirectIfLoggedIn(RegisterView)} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default AuthRouter