import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import { gql } from 'apollo-boost'
import { has } from 'lodash/object'
import { useQuery } from '@apollo/react-hooks'

import { MainLayout, Prompt } from '../components'
import { pluralize, secondsToText } from '../lib/util'

const QUERY = gql`
  {
    currentPrompt {
      id
      text
      responseTime
    }
    mostRecentDraft {
      id
      number
      draftfeedback {
        id
        draftrewrite {
          id
        }
      }
    }
  }
`

const ReadyView = ({ history }) => {
  const handleReady = () => {
    const { mostRecentDraft } = data

    if (mostRecentDraft && mostRecentDraft.draftfeedback && !mostRecentDraft.draftfeedback.draftrewrite) {
      // user's most recent draft has feedback and they haven't submitted a rewrite yet
      history.push('/draft/rewrite/')
    } else {
      history.push('/draft/write/')
    }
  }

  const { loading, error, data } = useQuery(QUERY)
  const hasPreviousDraft = has(data, 'mostRecentDraft') && data.mostRecentDraft !== null

  return (
    <MainLayout className='ReadyToWrite width-700'>
      {loading &&
        <LinearProgress indeterminate />
      }

      {error &&
        <p className='error'>Failed to fetch data</p>
      }

      {!loading && !error &&
        <>
          {hasPreviousDraft &&
            <p className='bottom-32'>
              You've already written on this prompt
              <span className='num-drafts-container'>{data.mostRecentDraft.number}</span>
              {pluralize('time', data.mostRecentDraft.number)} this week.
            </p>
          }

          {!hasPreviousDraft &&
            <p className='bottom-32'>
              It looks like this is your first time writing in response to this prompt.
            </p>
          }

          <div className='bottom-32'>
            <Prompt text={data.currentPrompt.text} />
          </div>

          <p className='bottom-32'>
            You will have <span className='standout'>{secondsToText(data.currentPrompt.responseTime)}</span> to
            write your response. Click the button below once you are ready to begin.
          </p>

          <div className='right'>
            <Button onClick={handleReady} unelevated>
              Let's Write
            </Button>
          </div>
        </>
      }
    </MainLayout>
  )
}

ReadyView.propTypes = {
  history: PropTypes.object.isRequired
}

export default ReadyView