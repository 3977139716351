import React, { useState } from 'react'
import Button from '@material/react-button'
import IconButton from '@material/react-icon-button'
import LinearProgress from '@material/react-linear-progress'
import MaterialIcon from '@material/react-material-icon'
import classNames from 'classnames'
import { gql } from 'apollo-boost'
import { sortBy } from 'lodash/collection'
import { useQuery } from '@apollo/react-hooks'

import IndexIndicators from '../IndexIndicators'
import PDFViewer from '../PDFViewer'
import { ShareDraftDialog } from '../ShareDraft'
import './DraftsViewer.scss'

const MY_DRAFTS = gql`
  {
    myDrafts {
      id
      file
      created
      teacher {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`

const DraftsViewer = () => {
  const [draftIndex, setDraftIndex] = useState(null)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const getTeacherInfo = (draft) => {
    const { email, firstName, lastName } = draft.teacher.user
    return `${firstName} ${lastName} (${email})`
  }

  const { loading, error, data } = useQuery(MY_DRAFTS, {
    onCompleted: data => setDraftIndex(data.myDrafts.length - 1),
    variables: {}
  })

  if (loading || draftIndex === null)
    return <LinearProgress indeterminate />

  if (error)
    return <p className='error'>Failed to load your drafts</p>

  const drafts = sortBy(data.myDrafts, 'created')
  const currentDraft = drafts[draftIndex]
  const isMostRecentDraft = draftIndex === drafts.length - 1

  return (
    <div className='DraftsViewer'>
      <div className='drafts-navigation'>
        <IconButton
          className={classNames({ hidden: draftIndex === 0 })}
          onClick={() => setDraftIndex(draftIndex - 1)}
        >
          <MaterialIcon icon='arrow_back' />
        </IconButton>

        <PDFViewer file={currentDraft.file} />

        <IconButton
          className={classNames({ hidden: isMostRecentDraft })}
          onClick={() => setDraftIndex(draftIndex + 1)}
        >
          <MaterialIcon icon='arrow_forward' />
        </IconButton>
      </div>

      <IndexIndicators
        currentIndex={draftIndex}
        length={drafts.length}
        onSelect={index => setDraftIndex(index)}
      />

      <div className='centered'>
        {currentDraft.teacher &&
          <p>
            This draft {isMostRecentDraft ? 'will be' : 'was made'} available for viewing to&nbsp;
            <span className='standout'>{getTeacherInfo(currentDraft)}</span>
          </p>
        }

        {!currentDraft.teacher &&
          <div className='p-with-btn'>
            <p>
              This draft {isMostRecentDraft ? 'will not be' : 'was not made'} available for viewing to a teacher
            </p>
            {isMostRecentDraft &&
              <Button
                className='btn-secondary'
                onClick={() => setDialogIsOpen(true)}
              >
                Change
              </Button>
            }
          </div>
        }
      </div>

      <div className='centered'>
        <Button
          icon={<MaterialIcon icon='file_download' />}
          href={currentDraft.file}
          target='_blank'
          unelevated
        >
          Download
        </Button>
      </div>

      <ShareDraftDialog
        draftId={currentDraft.id}
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
      />
    </div>
  )
}

export default DraftsViewer