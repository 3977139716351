import moment from 'moment'

export const alreadyWroteToday = (mostRecentDraft) => {
  return mostRecentDraft && moment().isSame(mostRecentDraft.created, 'day')
}

export const getDraftData = (data) => {
  const draftData = {
    draft: null,
    draftFeedback: null,
    draftRewrite: null
  }

  if (data) {
    if (data.mostRecentDraft) {
      draftData.draft = data.mostRecentDraft

      if (data.mostRecentDraft.draftfeedback) {
        draftData.draftFeedback = data.mostRecentDraft.draftfeedback

        if (data.mostRecentDraft.draftfeedback.draftrewrite) {
          draftData.draftRewrite = data.mostRecentDraft.draftfeedback.draftrewrite
        }
      }
    }
  }

  return draftData
}

export const getPrivileges = (user) => {
  const privileges = []

  if (user.isSuperuser)
    privileges.push('Admin')

  if (user.teacher !== null)
    privileges.push('Teacher')

  return privileges.join(', ')
}

export const getUrl = (url) => {
  const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : ''
  return baseUrl + url
}

export const pluralize = (word, count) => count === 1 ? word : `${word}s`

export const secondsToText = (seconds) => {
  let text = ''
  const minutes = Math.floor(seconds / 60)
  const secondsRemaining = seconds % 60

  if (minutes > 0)
    text += `${minutes} ${pluralize('minute', minutes)}`

  if (secondsRemaining > 0)
    text += ` ${secondsRemaining} ${pluralize('second', secondsRemaining)}`

  return text
}

export const wordCount = (str) => {
  str = str.trim()

  if (str.length === 0)
    return 0

  return str.split(/\s+/).length
}