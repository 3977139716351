import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Prompt.scss'

const Prompt = ({ className, text }) => {
  return (
    <div className={classNames('Prompt', className)}>
      <p className='text'>
        {text}
      </p>
    </div>
  )
}

Prompt.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
}

export default Prompt