import React from 'react'
import { Link } from 'react-router-dom'

import MainLayout from './MainLayout'

const NotFound = () => (
  <MainLayout className='width-700'>
    <h1 className='page-header'>
      Page Not Found
    </h1>

    <p>
      The page you are looking for does not seem to exist. <Link to='/'>Click here to return to the home page.</Link>
    </p>
  </MainLayout>
)

export default NotFound