import React, { useState } from 'react'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import MaterialIcon from '@material/react-material-icon'
import moment from 'moment'
import { gql } from 'apollo-boost'
import { orderBy } from 'lodash/collection'
import { useQuery } from '@apollo/react-hooks'

import CreateEditPromptDialog from './CreateEditPromptDialog'
import { Prompt } from '../components'
import { secondsToText } from '../lib/util'
import './PromptsView.scss'

const PROMPTS = gql`
  {
    prompts {
      id
      text
      responseTime
      created
    }
  }
`

const PromptsView = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [prompt, setPrompt] = useState(null)
  const { loading, error, data, refetch } = useQuery(PROMPTS)

  const handleDialogClose = (action) => {
    setDialogIsOpen(false)
    setPrompt(null)

    if (action === 'createSuccess')
      refetch()
  }

  if (loading)
    return <LinearProgress indeterminate />

  if (error)
    return <p className='centered error'>Failed to fetch prompts</p>

  const prompts = orderBy(data.prompts, 'created', 'desc')

  return (
    <div className='PromptsView'>
      <div className='bottom-48'>
        <Button
          className='btn-filled-secondary'
          icon={<MaterialIcon icon='add' />}
          onClick={() => setDialogIsOpen(true)}
          unelevated
        >
          New Prompt
        </Button>
      </div>

      {prompts.map(prompt => (
        <div className='bottom-48' key={prompt.id}>
          <p className='prompt-info info'>
            Created {moment(prompt.created).format('L')}
            <span className='separator'>&bull;</span>
            {secondsToText(prompt.responseTime)}
          </p>

          <div className='prompt-container'>
            <Prompt text={prompt.text} />

            <Button
              className='btn-secondary'
              onClick={() => {
                setDialogIsOpen(true)
                setPrompt(prompt)
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      ))}

      <CreateEditPromptDialog
        prompt={prompt}
        open={dialogIsOpen}
        onClose={handleDialogClose}
      />
    </div>
  )
}

export default PromptsView