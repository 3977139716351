import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material/react-linear-progress'
import { gql } from 'apollo-boost'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'

import ConfirmTeacher from './ConfirmTeacher'
import ShareAgain from './ShareAgain'
import ShareNew from './ShareNew'
import './ShareDraft.css'

const MOST_RECENT_TEACHER = gql`
  {
    mostRecentTeacher {
      id
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`

const VIEWS = {
  confirmTeacher: 'confirmTeacher',
  shareAgain: 'shareAgain',
  shareNew: 'shareNew'
}

/**
 * Component for selecting a teacher to share a draft with
 * @returns the selected teacherId, or null if no teacher selected
 */
const ShareDraft = ({ onSelectTeacher }) => {
  const [teacher, setTeacher] = useState(null)
  const [view, setView] = useState(null)
  const client = useApolloClient()

  const chooseDifferentTeacher = () => {
    setTeacher(null)
    setView(VIEWS.shareNew)
  }

  const chooseNewTeacher = (teacher) => {
    setTeacher(teacher)
    setView(VIEWS.confirmTeacher)
  }

  const confirmTeacher = () => {
    client.writeData({ data: { shareDraftTeacherId: teacher.id } })
    onSelectTeacher(teacher.id)
  }

  const doNotShare = () => {
    client.writeData({ data: { shareDraftTeacherId: null } })
    onSelectTeacher(null)
  }

  const handleQueryCompleted = (data) => {
    if (data.mostRecentTeacher) {
      setTeacher(data.mostRecentTeacher)
      setView(VIEWS.shareAgain)
    } else {
      setView(VIEWS.shareNew)
    }
  }

  const handleQueryError = () => {
    setView(VIEWS.shareNew)
  }

  const { loading } = useQuery(MOST_RECENT_TEACHER, {
    onCompleted: handleQueryCompleted,
    onError: handleQueryError,
    variables: {}
  })

  if (loading)
    return <LinearProgress indeterminate />

  return (
    <div className='ShareDraft'>
      {view === VIEWS.shareAgain &&
        <ShareAgain
          teacher={teacher}
          onChooseSomeoneElse={chooseDifferentTeacher}
          onConfirm={confirmTeacher}
          onDoNotShare={doNotShare}
        />
      }

      {view === VIEWS.shareNew &&
        <ShareNew
          onDoNotShare={doNotShare}
          onSubmit={chooseNewTeacher}
        />
      }

      {view === VIEWS.confirmTeacher &&
        <ConfirmTeacher
          teacher={teacher}
          onCancel={chooseDifferentTeacher}
          onConfirm={confirmTeacher}
        />
      }
    </div>
  )
}

ShareDraft.propTypes = {
  onSelectTeacher: PropTypes.func.isRequired
}

export default withRouter(ShareDraft)