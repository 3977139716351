import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material/react-button'

const ShareAgain = ({ onChooseSomeoneElse, onConfirm, onDoNotShare, teacher }) => {
  const { user: { email,  firstName, lastName } } = teacher

  return (
    <div>
      <p className='bottom-32'>
        You shared your last writing with <span className='standout'>{firstName} {lastName} ({email})</span>. Would you
        like to continue sharing your writing with them?
      </p>
      <div className='btns'>
        <Button className='btn-filled-gray' onClick={onDoNotShare} unelevated>
          No, Don't Share
        </Button>
        <Button className='btn-filled-secondary' onClick={onChooseSomeoneElse} unelevated>
          No, Someone Else
        </Button>
        <Button onClick={onConfirm} unelevated>
          Yes
        </Button>
      </div>
    </div>
  )
}

ShareAgain.propTypes = {
  onChooseSomeoneElse: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDoNotShare: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired
}

export default ShareAgain