import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import { Snackbar } from '@material/react-snackbar'
import TextField, { HelperText, Input } from '@material/react-text-field'
import isEmail from 'validator/lib/isEmail'
import normalizeEmail from 'validator/lib/normalizeEmail'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import { CsrfTokenContext } from '../CsrfTokenProvider'
import { MainLayout } from '../components'

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      error
      user {
        id
        firstName
        lastName
        isSuperuser
        teacher {
          id
        }
      }
    }
  }
`

const LoginView = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [passwordRequired, setPasswordRequired] = useState(false)
  const { updateCsrfToken } = useContext(CsrfTokenContext)

  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      const { error, user } = data.login

      if (error) {
        if (error === 'invalid_credentials')
          setInvalidCredentials(true)
        else if (error === 'invalid_email')
          history.push('/auth/register/', { email })
        else if (error === 'password_required')
          setPasswordRequired(true)
      } else if (user) {
        // logging in sets a new CSRF token, which needs to be updated in the Apollo Client
        updateCsrfToken()

        if (user.teacher)
          history.push('/teacher/')
        else if (user.isSuperuser)
          history.push('/admin/')
        else
          history.replace('/home/')
      }
    }
  })

  const renderHelperText = () => {
    return emailIsValid ? null : <HelperText validation persistent>Please enter a valid email address</HelperText>
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setInvalidCredentials(false)

    if (isEmail(email)) {
      setEmailIsValid(true)
      login({
        variables: {
          input: {
            email: normalizeEmail(email),
            password: passwordRequired ? password : null
          }
        }
      })
    } else {
      setEmailIsValid(false)
    }
  }

  return (
    <MainLayout className='Login' showCenteredHeader>
      {invalidCredentials &&
        <p className='bottom-32 centered error'>
          Invalid email or password
        </p>
      }

      {!invalidCredentials &&
        <p className='bottom-32 centered'>
          Please enter your email {passwordRequired ? 'and password to log in' : 'to get started'}
        </p>
      }

      <form className='width-400' onSubmit={handleSubmit}>
        <div className='form-field'>
          <TextField
            label='Email'
            helperText={renderHelperText()}
            outlined
          >
            <Input
              value={email}
              onChange={e => {
                setEmail(e.target.value)
                setEmailIsValid(true)
              }}
              isValid={emailIsValid}
              disabled={loading}
              autoFocus={!passwordRequired}
              required
            />
          </TextField>
        </div>

        {passwordRequired &&
          <div className='form-field'>
            <TextField label='Password' outlined>
              <Input
                id='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
                autoFocus
                required
              />
            </TextField>
          </div>
        }

        <div className='centered'>
          <Button disabled={loading} unelevated>
            Submit
          </Button>
        </div>
      </form>

      {error &&
        <Snackbar
          message='An error occurred'
          actionText='Dismiss'
          timeoutMS={5000}
        />
      }
    </MainLayout>
  )
}

LoginView.propTypes = {
  history: PropTypes.object.isRequired
}

export default LoginView