import React, { useState } from 'react'
import Button from '@material/react-button'

import PromptsView from './PromptsView'
import SchedulingView from './SchedulingView'
import UsersView from './UsersView'
import { MainLayout } from '../components'
import './AdminView.scss'

const VIEWS = {
  prompts: 'Prompts',
  scheduling: 'Scheduling',
  users: 'Users'
}

const AdminView = () => {
  const [view, setView] = useState(VIEWS.scheduling)
  const views = Object.values(VIEWS).sort()

  return (
    <MainLayout className='AdminView width-900'>
      <div className='btns bottom-64'>
        {views.map(v => (
          <Button
            outlined={v !== view}
            unelevated={v === view}
            onClick={() => setView(v)}
            key={v}
          >
            {v}
          </Button>
        ))}
      </div>

      {view === VIEWS.prompts &&
        <PromptsView />
      }

      {view === VIEWS.scheduling &&
        <SchedulingView />
      }

      {view === VIEWS.users &&
        <UsersView />
      }
    </MainLayout>
  )
}

export default AdminView