import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import MaterialIcon from '@material/react-material-icon'
import moment from 'moment'

import './DraftListItem.scss'

const DraftListItem = ({ draft, onMarkClick, onUploadClick }) => {
  const hasFeedback = draft.draftfeedback != null
  const feedbackHasFile = hasFeedback && draft.draftfeedback.file !== ''
  const expiresAt = moment(draft.created).add(24, 'hours')

  if (moment().isAfter(expiresAt)) {
    // the time in which to download this draft has expired, do not display
    return null
  }

  return (
    <div className='DraftListItem row'>
      <div className='col-2 centered'>
        {hasFeedback &&
          <MaterialIcon className='green feedback-indicator' icon='check_circle' />
        }

        {!hasFeedback &&
          <Button onClick={() => onMarkClick(draft)}>
            Mark
          </Button>
        }
      </div>
      <div>
        {draft.user.name}
      </div>
      <div className='col-2'>
        {draft.number}
      </div>
      <div className='col-2'>
        {expiresAt.fromNow(true)}
      </div>
      <Button
        className='btn-secondary'
        icon={<MaterialIcon icon='file_download' />}
        href={draft.file}
        target='_blank'
      >
        Download
      </Button>
      <Button icon={<MaterialIcon icon='file_upload' />} onClick={() => onUploadClick(draft)}>
        {feedbackHasFile ? 'Re-Upload' : 'Upload'}
      </Button>
    </div>
  )
}

DraftListItem.propTypes = {
  draft: PropTypes.shape({
    created: PropTypes.string.isRequired,
    draftfeedback: PropTypes.object,
    file: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onMarkClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired
}

export default DraftListItem