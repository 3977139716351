import React from 'react'
import MaterialIcon from '@material/react-material-icon'
import classNames from 'classnames'

const Info = ({ children, className }) => {
  return (
    <p className={classNames('info with-icon', className)}>
      <MaterialIcon icon='error' className='secondary' />
      {children}
    </p>
  )
}

export default Info