import React, { useState } from 'react'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import TextField, { Input } from '@material/react-text-field'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import EditUserDialog from './EditUserDialog'
import { Info } from '../components'
import { getPrivileges } from '../lib/util'

const USERS = gql`
  query Users($email: String!) {
    users(email: $email) {
      id
      name
      email
      isSuperuser
      teacher {
        id
      }
    }
  }
`

const UsersView = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [userId, setUserId] = useState(null)

  const { error, data, networkStatus } = useQuery(USERS, {
    skip: email === '',
    variables: { email }
  })

  // we only care about initial load, not refetch
  const loading = networkStatus === 1

  return (
    <div className='UsersView'>
      <p className='bottom-32 centered'>
        Search users by entering an email below
      </p>
      <div className='form-field bottom-48 width-400'>
        <TextField label='Email' outlined>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
        </TextField>
      </div>

      {loading &&
        <LinearProgress indeterminate />
      }

      {error &&
        <p className='error'>Failed to fetch users</p>
      }

      {data && data.users &&
        <div>
          {data.users.length === 0 &&
            <Info className='centered'>
              No users were found that match the given email
            </Info>
          }

          {data.users.length > 0 &&
            <div className='list'>
              <div className='row header'>
                <div>Name</div>
                <div>Email</div>
                <div>Privileges</div>
                <Button className='col-2 hidden'>Edit</Button>
              </div>

              {data.users.map(user => (
                <div className='row' key={user.id}>
                  <div>{user.name}</div>
                  <div>{user.email}</div>
                  <div>{getPrivileges(user)}</div>
                  <Button
                    className='btn-secondary col-2'
                    onClick={() => {
                      setDialogIsOpen(true)
                      setUserId(user.id)
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ))}
            </div>
          }
        </div>
      }

      <EditUserDialog
        userId={userId}
        open={dialogIsOpen}
        onClose={() => {
          setDialogIsOpen(false)
          setUserId(null)
        }}
      />
    </div>
  )
}

export default UsersView