import React, { Component } from 'react'

import { MainLayout } from './components'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <MainLayout className='width-700'>
          <h1 className='page-header'>
            Error
          </h1>
          <p>
            Uh-oh! Something went wrong. <a href='/'>Click here to go to the home page.</a>
          </p>
        </MainLayout>
      )
    }

    return this.props.children
  }

}

export default ErrorBoundary