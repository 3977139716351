import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material/react-linear-progress'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const LOGOUT = gql`
  mutation {
    logout {
      success
    }
  }
`

const Logout = ({ history }) => {
  const handleMutationCompleted = (data) => {
    if (data.logout.success)
      history.push('/')
  }

  const client = useApolloClient()
  const [logout] = useMutation(LOGOUT, { onCompleted: handleMutationCompleted })

  useEffect(() => {
    client.clearStore()
    logout()
  }, [client, logout])

  return <LinearProgress indeterminate />
}

Logout.propTypes = {
  history: PropTypes.object.isRequired
}

export default Logout