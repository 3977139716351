import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import './LinkButton.css'

const LinkButton = ({ buttonClassName, children, className, to, ...otherProps }) => {
  return (
    <Link className={classNames('LinkButton', className)} to={to}>
      <Button className={classNames(buttonClassName)} {...otherProps}>
        {children}
      </Button>
    </Link>
  )
}

LinkButton.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string.isRequired
}

export default LinkButton