import React, { useState } from 'react'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import classNames from 'classnames'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import { DraftsViewer, Info, MainLayout } from '../components'
import { ShareDraftDialog } from '../components/ShareDraft'
import { alreadyWroteToday } from '../lib/util'

const MOST_RECENT_DRAFT = gql`
  {
    mostRecentDraft {
      id
      created
      teacher {
        id
      }
    }
  }
`

const SubmittedView = ({ history }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const { loading, error, data } = useQuery(MOST_RECENT_DRAFT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!alreadyWroteToday(data.mostRecentDraft))
        history.replace('/draft/')
    }
  })

  const isReady = !loading && !error && data.mostRecentDraft
  const mostRecentDraftNotShared = data && data.mostRecentDraft && data.mostRecentDraft.teacher === null

  return (
    <MainLayout className='DraftsSubmitted width-700'>
      {loading &&
        <LinearProgress indeterminate />
      }

      {error &&
        <p className='error'>Failed to fetch data</p>
      }

      {isReady &&
        <>
          <p className={classNames({ 'bottom-32': !mostRecentDraftNotShared })}>
            Great work! Your writing has successfully been submitted. Here's what your effort today looks like.
          </p>

          {mostRecentDraftNotShared &&
            <div className='bottom-32 centered p-with-btn'>
              <Info>
                The writing you just submitted has not been shared with a teacher
              </Info>

              <Button className='btn-secondary' onClick={() => setDialogIsOpen(true)}>
                Change
              </Button>
            </div>
          }

          <DraftsViewer />

          <ShareDraftDialog
            draftId={data.mostRecentDraft.id}
            open={dialogIsOpen}
            onClose={() => setDialogIsOpen(false)}
          />
        </>
      }
    </MainLayout>
  )
}

export default SubmittedView