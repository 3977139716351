import { gql } from 'apollo-boost'

export const MY_USER = gql`
  {
    myUser {
      id
      firstName
      lastName
      isSuperuser
      teacher {
        id
      }
    }
  }
`