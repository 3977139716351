import React, { useState } from 'react'
import cookies from 'js-cookie'

export const CsrfTokenContext = React.createContext()

const CsrfTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState(cookies.get('csrftoken'))

  const updateCsrfToken = () => {
    setCsrfToken(cookies.get('csrftoken'))
  }

  return (
    <CsrfTokenContext.Provider value={{ csrfToken, updateCsrfToken }}>
      {children}
    </CsrfTokenContext.Provider>
  )
}

export default CsrfTokenProvider