import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material/react-linear-progress'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import { LinkButton, MainLayout, Prompt } from '../components'

const CURRENT_PROMPT = gql`
  {
    currentPrompt {
      id
      text
    }
  }
`

const LandingPage = () => {
  let { loading, error, data } = useQuery(CURRENT_PROMPT)

  const hasPrompt = !loading && !error && data.currentPrompt
  const noPrompt = !loading && !error && !data.currentPrompt

  return (
    <MainLayout showCenteredHeader>
      <div className='LandingPage width-700'>
        {loading &&
          <LinearProgress indeterminate />
        }

        {error &&
          <p className='bottom-32 centered error'>
            Failed to fetch current prompt
          </p>
        }

        {hasPrompt &&
          <div>
            <p className='bottom-16'>
              This week we are writing responses to this prompt:
            </p>

            <Prompt className='bottom-32' text={data.currentPrompt.text} />
          </div>
        }

        {noPrompt &&
          <p className='bottom-32 centered'>
            There is no prompt at this time. Check back again later.
          </p>
        }

        {!loading &&
          <LinkButton className='bottom-32 centered' to='/auth/login/' unelevated>
            Write Now
          </LinkButton>
        }
      </div>
    </MainLayout>
  )
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired
}

export default LandingPage