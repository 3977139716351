import React, { useState } from 'react'
import Button from '@material/react-button'
import LinearProgress from '@material/react-linear-progress'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'

import DownloadDialog from './DownloadDialog'
import DraftList from './DraftList'
import UploadDialog from './UploadDialog'
import { MainLayout, WelcomeHeader } from '../components'
import { pluralize } from '../lib/util'

const MY_SHARED_DRAFTS = gql`
  {
    mySharedDrafts {
      id
      file
      number
      created
      draftfeedback {
        id
        file
      }
      user {
        id
        name
      }
    }
  }
`

const CREATE_DRAFT_FEEDBACK = gql`
  mutation CreateDraftFeedback($input: CreateDraftFeedbackInput!) {
    createDraftFeedback(input: $input) {
      drafts {
        id
        draftfeedback {
          id
          file
        }
      }
    }
  }
`

const TeacherView = () => {
  const [downloadDialogIsOpen, setDownloadDialogIsOpen] = useState(false)
  const [selectedDraft, setSelectedDraft] = useState(null)
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false)
  const [createDraftFeedback, { error: createFeedbackError }] = useMutation(CREATE_DRAFT_FEEDBACK)

  const handleCloseDialog = (action) => {
    setSelectedDraft(null)
    setUploadDialogIsOpen(false)

    if (action === 'uploadSuccess')
      refetch()
  }

  const handleMarkClick = (draft) => {
    createDraftFeedback({
      variables: {
        input: {
          draftId: draft.id
        }
      }
    })
  }

  const handleMarkAllClick = () => {
    const draftIds = drafts.filter(d => !d.draftfeedback).map(d => d.id)

    if (draftIds.length === 0)
      return

    createDraftFeedback({
      variables: {
        input: {
          draftIds
        }
      }
    })
  }

  const handleUploadClick = (draft) => {
    setSelectedDraft(draft)
    setUploadDialogIsOpen(true)
  }

  const { error, data, networkStatus, refetch } = useQuery(MY_SHARED_DRAFTS)
  const loading = networkStatus === 1 // first time fetching data, does not include refetching
  let drafts, numDraftsAwaitingFeedback

  if (!loading && !error) {
    drafts = data.mySharedDrafts
    numDraftsAwaitingFeedback = drafts.filter(d => !d.draftfeedback).length
  }

  return (
    <MainLayout>
      <div className='TeacherHome width-900'>
        <WelcomeHeader />

        {loading &&
          <LinearProgress indeterminate />
        }

        {error &&
          <p className='error'>Failed to fetch drafts</p>
        }

        {drafts &&
          <div>
            {drafts.length === 0 &&
              <p>You do not have any drafts currently awaiting feedback</p>
            }

            {drafts.length > 0 &&
              <div>
                <p>
                  You have<span className='num-drafts-container'>{numDraftsAwaitingFeedback}</span>
                  {pluralize('draft', numDraftsAwaitingFeedback)} awaiting feedback.
                </p>
                <p className='bottom-32'>
                  You can download a user's draft below, as well as upload feedback. If you do not want to upload
                  feedback, but still want to indicate that feedback has been given, then click the&nbsp;
                  <span className='standout'>Mark</span> button next to a user's name. Drafts are available for
                  teacher download for <span className='standout'>24 hours</span> after submission.
                </p>

                <div className='btns-inline bottom-32'>
                  <Button onClick={handleMarkAllClick} unelevated>
                    Mark All
                  </Button>

                  <Button
                    className='btn-filled-secondary'
                    onClick={() => setDownloadDialogIsOpen(true)}
                    unelevated
                  >
                    Download All
                  </Button>
                </div>

                <DraftList
                  drafts={drafts}
                  onMarkClick={handleMarkClick}
                  onUploadClick={handleUploadClick}
                />

                <DownloadDialog
                  drafts={drafts}
                  open={downloadDialogIsOpen}
                  onClose={() => setDownloadDialogIsOpen(false)}
                />
              </div>
            }

            <UploadDialog
              draft={selectedDraft}
              open={uploadDialogIsOpen}
              onClose={handleCloseDialog}
            />
          </div>
        }
      </div>

      {createFeedbackError &&
        <Snackbar
          message='Failed to mark draft as having feedback'
          actionText='Dismiss'
          timeoutMS={5000}
        />
      }
    </MainLayout>
  )
}

export default TeacherView