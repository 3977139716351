import React, { useEffect, useMemo, useState } from 'react'
import Button from '@material/react-button'
import IconButton from '@material/react-icon-button'
import LinearProgress from '@material/react-linear-progress'
import MaterialIcon from '@material/react-material-icon'
import classNames from 'classnames'
import moment from 'moment'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import SchedulePromptDialog from './SchedulePromptDialog'
import { Info, Prompt } from '../components'
import { getMonthArray } from '../lib/calendar'
import { secondsToText } from '../lib/util'
import './SchedulingView.scss'

const PROMPT_INSTANCES = gql`
  query PromptInstances($fromDate: String!, $toDate: String!) {
    promptInstances(fromDate: $fromDate, toDate: $toDate) {
      id
      startDate
      endDate
      hasDrafts
      prompt {
        id
        text
        responseTime
      }
    }
  }
`

const VIEWS = {
  error: 'error',
  noSelection: 'noSelection',
  weekAvailable: 'weekAvailable',
  weekScheduled: 'weekScheduled'
}

const isToday = (day) => {
  return moment(day).isSame(moment(), 'day')
}

const SchedulingView = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [momentObj, setMomentObj] = useState(moment())
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(null)
  const monthArray = useMemo(() => getMonthArray(momentObj.year(), momentObj.month()), [momentObj])
  const fromDate = moment(monthArray[0][0]).format()
  const toDate = moment(monthArray[monthArray.length - 1][6]).endOf('day').format()

  useEffect(() => {
    const now = moment()

    if (now.isSame(momentObj, 'month')) {
      monthArray.forEach((week, index) => {
        week.forEach(day => {
          if (moment(day).isSame(now, 'day')) {
            setSelectedWeekIndex(index)
          }
        })
      })
    } else {
      setSelectedWeekIndex(null)
    }
  }, [momentObj, monthArray])

  const { loading, error, data, refetch } = useQuery(PROMPT_INSTANCES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      fromDate,
      toDate
    }
  })

  const promptInstancesByWeek = monthArray.map(week => {
    const firstWeekDay = moment(week[1])
    return (data && data.promptInstances) ?
      data.promptInstances.find(pi => moment(pi.startDate).isSame(firstWeekDay, 'day')) :
      undefined
  })

  let selectedWeek = null

  if (selectedWeekIndex !== null) {
    selectedWeek = {
      startDate: monthArray[selectedWeekIndex][1],
      endDate: monthArray[selectedWeekIndex][5],
      promptInstance: promptInstancesByWeek[selectedWeekIndex]
    }
  }

  let view

  if (error)
    view = VIEWS.error
  else if (selectedWeek === null)
    view = VIEWS.noSelection
  else if (selectedWeek.promptInstance === undefined)
    view = VIEWS.weekAvailable
  else
    view = VIEWS.weekScheduled

  const format = 'MMM D'
  const isCurrentPrompt = view === VIEWS.weekScheduled &&
    moment().isBetween(selectedWeek.promptInstance.startDate, selectedWeek.promptInstance.endDate, null, '[]')

  return (
    <div className='SchedulingView'>
      <div className='calendar-navigation-top'>
        <IconButton onClick={() => setMomentObj(moment(momentObj).subtract(1, 'month'))}>
          <MaterialIcon icon='arrow_back' />
        </IconButton>
        <IconButton onClick={() => setMomentObj(moment(momentObj).add(1, 'month'))}>
          <MaterialIcon icon='arrow_forward' />
        </IconButton>
      </div>
      <div className='calendar-navigation bottom-64'>
        <IconButton onClick={() => setMomentObj(moment(momentObj).subtract(1, 'month'))}>
          <MaterialIcon icon='arrow_back' />
        </IconButton>

        <div className='calendar'>
          <header className='centered'>
            {momentObj.format('MMMM YYYY')}
          </header>

          {loading &&
            <div className='centered'>
              <LinearProgress className='loading' indeterminate />
            </div>
          }

          <div className='month'>
            <div className='week headers'>
              <div className='day'>Sun</div>
              <div className='selected-wrapper'>
                <div className='week-days'>
                  <div className='day'>Mon</div>
                  <div className='day'>Tue</div>
                  <div className='day'>Wed</div>
                  <div className='day'>Thu</div>
                  <div className='day'>Fri</div>
                </div>
              </div>
              <div className='day'>Sat</div>
            </div>

            {monthArray.map((week, index) => {
              const weekDays = week.slice(1, 6)
              const promptInstance = promptInstancesByWeek[index]
              const weekDaysClass = classNames('week-days', {
                available: promptInstance === undefined,
                scheduled: promptInstance !== undefined
              })

              return (
                <div className='week' key={index}>
                  <div className={classNames('day disabled', { today: isToday(week[0]) })}>
                    {week[0].day}
                  </div>

                  <div className={classNames('selected-wrapper', {selected: selectedWeekIndex === index})}>
                    <div className={weekDaysClass} onClick={() => setSelectedWeekIndex(index)}>
                      {weekDays.map(day => {
                        return (
                          <div className={classNames('day', { today: isToday(day) })} key={day.day}>
                            {day.day}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className={classNames('day disabled', { today: isToday(week[6]) })}>
                    {week[6].day}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <IconButton onClick={() => setMomentObj(moment(momentObj).add(1, 'month'))}>
          <MaterialIcon icon='arrow_forward' />
        </IconButton>
      </div>

      {view === VIEWS.error &&
        <p className='centered error'>
          Failed to load schedule
        </p>
      }

      {view === VIEWS.noSelection &&
        <p>
          Click on one of the weeks in the calendar above to either schedule a prompt or view a prompt that has
          already been scheduled.
        </p>
      }

      {view === VIEWS.weekAvailable &&
        <div>
          <p className='centered'>
            There is no prompt scheduled for the selected week
          </p>
          <div className='centered'>
            <Button onClick={() => setDialogIsOpen(true)} unelevated>
              Schedule Prompt
            </Button>
          </div>
        </div>
      }

      {view === VIEWS.weekScheduled &&
        <div>
          <p>
            {isCurrentPrompt &&
              <>
                <span className='standout'>Current Prompt</span
                ><span className='separator'>&bull;</span>
              </>
            }

            {moment(selectedWeek.startDate).format(format)} &ndash; {moment(selectedWeek.endDate).format(format)}
            <span className='separator'>&bull;</span>
            {secondsToText(selectedWeek.promptInstance.prompt.responseTime)}
          </p>

          <Prompt className='bottom-32' text={selectedWeek.promptInstance.prompt.text} />

          {selectedWeek.promptInstance.hasDrafts &&
            <Info>
              The prompt for this week cannot be changed due to the fact that drafts have already been submitted for
              this scheduled prompt
            </Info>
          }

          {!selectedWeek.promptInstance.hasDrafts &&
            <Button onClick={() => setDialogIsOpen(true)} unelevated>
              Change
            </Button>
          }
        </div>
      }

      {(view === VIEWS.weekAvailable || view === VIEWS.weekScheduled) &&
        <SchedulePromptDialog
          open={dialogIsOpen}
          selectedWeek={selectedWeek}
          onClose={action => {
            setDialogIsOpen(false)

            if (action === 'createPromptInstanceSuccess') {
              refetch()
            }
          }
          }
        />
      }
    </div>
  )
}

export default SchedulingView