import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import TextField, { Input } from '@material/react-text-field'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import './CreateEditPromptDialog.scss'

const CREATE_PROMPT = gql`
  mutation CreatePrompt($input: CreatePromptInput!) {
    createPrompt(input: $input) {
      prompt {
        id
        text
        responseTime
      }
    }
  }
`

const UPDATE_PROMPT = gql`
  mutation UpdatePrompt($input: UpdatePromptInput!) {
    updatePrompt(input: $input) {
      prompt {
        id
        text
        responseTime
      }
    }
  }
`

const CreateEditPromptDialog = ({ onClose, open, prompt }) => {
  const [text, setText] = useState('')
  const [responseTime, setResponseTime] = useState('')
  const [snackbarText, setSnackbarText] = useState(null)

  const [createPrompt, { loading: createPromptLoading }] = useMutation(CREATE_PROMPT, {
    onCompleted: () => {
      setSnackbarText('Successfully created new prompt')
      onClose('createSuccess')
      resetState()
    },
    onError: () => snackbarText('Failed to create new prompt')
  })

  const [updatePrompt, { loading: updatePromptLoading }] = useMutation(UPDATE_PROMPT, {
    onCompleted: (data) => {
      setSnackbarText('Successfully edited the prompt')
      onClose('updateSuccess')
    },
    onError: () => setSnackbarText('Failed to edit the prompt')
  })

  const resetState = () => {
    setText('')
    setResponseTime('')
  }

  useEffect(() => {
    if (prompt) {
      setText(prompt.text)
      setResponseTime(prompt.responseTime)
    } else {
      resetState()
    }
  }, [prompt])

  const handleSubmit = () => {
    setSnackbarText(null)

    if (!text || !responseTime) {
      setSnackbarText('Prompt and Response Time are required')
      return
    }

    const input = { text, responseTime }

    if (prompt) {
      updatePrompt({
        variables: {
          input: {
            ...input,
            promptId: prompt.id
          }
        }
      })
    } else {
      createPrompt({
        variables: {
          input
        }
      })
    }
  }

  const disabled = createPromptLoading || updatePromptLoading
  const closeAction = disabled ? '' : 'close'

  return (
    <div className='AddEditPromptDialog'>
      <Dialog
        open={open}
        onClose={onClose}
        escapeKeyAction={closeAction}
        scrimClickAction={closeAction}
      >
        <DialogTitle>
          {prompt ? 'Edit' : 'Add'} Prompt
        </DialogTitle>

        <DialogContent className='content'>
          <TextField label='Prompt' className='bottom-32 full-width' textarea outlined>
            <Input
              id='text'
              value={text}
              onChange={e => setText(e.target.value)}
              disabled={disabled}
            />
          </TextField>

          <TextField label='Response Time (seconds)' className='full-width' outlined>
            <Input
              id='responseTime'
              type='number'
              value={responseTime}
              onChange={e => setResponseTime(e.target.value)}
              min={0}
              max={32767}
              disabled={disabled}
            />
          </TextField>
        </DialogContent>

        <DialogFooter>
          <DialogButton action='cancel' disabled={disabled}>
            Cancel
          </DialogButton>
          <DialogButton action='' onClick={handleSubmit} disabled={disabled}>
            {prompt ? 'Edit' : 'Add'}
          </DialogButton>
        </DialogFooter>
      </Dialog>

      {snackbarText &&
        <Snackbar
          message={snackbarText}
          actionText='Dismiss'
          onClose={() => setSnackbarText(null)}
          timeoutMS={5000}
        />
      }
    </div>
  )
}

CreateEditPromptDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  prompt: PropTypes.object
}

export default CreateEditPromptDialog