import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import MaterialIcon from '@material/react-material-icon'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'

import LinkButton from '../LinkButton'
import { MY_USER } from '../../graphql/queries'
import './AppHeader.scss'

const AppHeader = ({ location }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const { data } = useQuery(MY_USER)
  const user = data && data.myUser

  useEffect(() => {
    document.onclick = (e) => {
      if (e.target.innerText !== user.firstName.toUpperCase())
        setShowDropdown(false)
    }

    return () => document.onclick = null
  }, [user])

  let viewLinks = []

  if (user) {
    if (user.isSuperuser)
      viewLinks.push({
        label: 'Admin',
        to: '/admin/',
        selected: location.pathname === '/admin/'
      })

    if (user.teacher)
      viewLinks.push({
        label: 'Teacher',
        to: '/teacher/',
        selected: location.pathname === '/teacher/'
      })

    viewLinks.push({
      label: 'Writer',
      to: '/home/',
      selected: location.pathname === '/home/' || location.pathname.includes('draft')
    })
  }



  return (
    <div className='AppHeader'>
      <header>
        <Link to='/'>
          Daily TOEFL Writer
        </Link>
      </header>

      {user &&
        <div className='right-side'>
          <div>
            <Button onClick={() => setShowDropdown(!showDropdown)}>
              {user.firstName}
            </Button>

            {showDropdown &&
              <div className='dropdown'>
                {viewLinks.length > 1 &&
                  <>
                    {viewLinks.map(link =>
                      <LinkButton
                        buttonClassName='btn-secondary'
                        to={link.to}
                        icon={link.selected ? <MaterialIcon icon='check' /> : <MaterialIcon />}
                        key={link.label}
                      >
                        {link.label}
                      </LinkButton>
                    )}
                  </>
                }

                <LinkButton buttonClassName='btn-secondary' to='/auth/logout/' icon={<MaterialIcon icon='exit_to_app' />}>
                  Log Out
                </LinkButton>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

AppHeader.propTypes = {
  location: PropTypes.object.isRequired
}

export default withRouter(AppHeader)