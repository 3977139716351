import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material/react-linear-progress'
import { Route, Switch } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import SubmittedView from './SubmittedView'
import ReadyView from './ReadyView'
import RewriteView from './RewriteView'
import WriteView from './WriteView'
import { MainLayout, NotFound } from '../components'
import { alreadyWroteToday } from '../lib/util'

const QUERY = gql`
  {
    currentPrompt {
      id
    }
    mostRecentDraft {
      id
      created
      draftfeedback {
        id
        draftrewrite {
          id
        }
      }
    }
  }
`

const DraftRouter = ({ history }) => {
  const { loading, error } = useQuery(QUERY, {
    onCompleted: (data) => {
      if (data) {
        const { currentPrompt, mostRecentDraft } = data
        const redirectHome = !currentPrompt || alreadyWroteToday(mostRecentDraft)

        if (redirectHome)
          history.replace('/home/')
      }
    }
  })

  if (loading)
    return <LinearProgress indeterminate />

  if (error)
    return (
      <MainLayout>
        <p className='error'>Failed to fetch data</p>
      </MainLayout>
    )

  return (
    <Switch>
      <Route exact strict path='/draft/' component={ReadyView} />
      <Route exact strict path='/draft/submitted/' component={SubmittedView} />
      <Route exact strict path='/draft/write/' component={WriteView} />
      <Route exact strict path='/draft/rewrite/' component={RewriteView} />
      <Route component={NotFound} />
    </Switch>
  )
}

DraftRouter.propTypes = {
  history: PropTypes.object.isRequired
}

export default DraftRouter