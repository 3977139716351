import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { MY_USER } from '../graphql/queries'

const WelcomeHeader = () => {
  const { loading, data } = useQuery(MY_USER)

  if (loading)
    return null

  const { firstName, lastName } = data.myUser

  return (
    <h1 className='page-header'>
      Welcome, {firstName} {lastName}!
    </h1>
  )
}

export default WelcomeHeader