import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material/react-button'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import Switch from '@material/react-switch'

import { Info } from '../components'

const DownloadDialog = ({ drafts, onClose, open }) => {
  const [unmarkedOnly, setUnmarkedOnly] = useState(false)
  let draftIds = drafts

  if (unmarkedOnly)
    draftIds = drafts.filter(draft => !draft.draftfeedback)

  draftIds = draftIds.map(draft => draft.id).join(',')
  const hideBtns = unmarkedOnly && draftIds.length === 0

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          Download Drafts
        </DialogTitle>

        <DialogContent>
          <p className='bottom-32'>
            You can download all drafts as individual files that are zipped together (easier for providing individual
            feedback to upload), or you can download all drafts as a single file (easier for printing). Also, you can
            toggle whether you want to include all drafts, or only those that still need feedback.
          </p>

          <div className='bottom-32'>
            <Switch
              className='right-16'
              checked={unmarkedOnly}
              onChange={e => setUnmarkedOnly(e.target.checked)}
              nativeControlId='unmarked-switch'
            />
            <label htmlFor='unmarked-switch'>
              Only download drafts without feedback
            </label>
          </div>

          {hideBtns &&
            <Info>
              There are no drafts without feedback
            </Info>
          }

          {!hideBtns &&
            <div className='btns-inline'>
              <Button
                className='btn-filled-secondary'
                href={`/download/drafts_zip/?draft_ids=${draftIds}`}
                target='_blank'
                unelevated
              >
                Individual Files
              </Button>

              <Button
                className='btn-filled-secondary'
                href={`/download/drafts_pdf/?draft_ids=${draftIds}`}
                target='_blank'
                unelevated
              >
                Single File
              </Button>
            </div>
          }
        </DialogContent>

        <DialogFooter>
          <DialogButton action='done'>
            Done
          </DialogButton>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

DownloadDialog.propTypes = {
  drafts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default DownloadDialog