import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { range } from 'lodash/util'

import './IndexIndicators.scss'

const IndexIndicators = ({ currentIndex, length, onSelect }) => {
  return (
    <div className='IndexIndicators'>
      {range(length).map(index =>
        <div
          className={classNames('dot', { filled: currentIndex === index })} key={index}
          onClick={() => {
            if (onSelect)
              onSelect(index)
          }}
        />
      )}
    </div>
  )
}

IndexIndicators.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onSelect: PropTypes.func
}

export default IndexIndicators