import React from 'react'
import LinearProgress from '@material/react-linear-progress'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Editor, MainLayout } from '../components'
import { getDraftData } from '../lib/util'

const QUERY = gql`
  {
    currentPrompt {
      id
      text
      responseTime
    }
    mostRecentDraft {
      id
      text
      number
      draftfeedback {
        id
        draftrewrite {
          id
          text
        }
      }
    }
    shareDraftTeacherId @client
  }
`

const CREATE_DRAFT = gql`
  mutation CreateDraft ($input: DraftInput!) {
    createDraft(input: $input) {
      draft {
        id
      }
    }
  }
`

const WriteView = ({ history }) => {
  const handleSubmitDraft = (text) => {
    const draftNumber = mostRecentDraft ? mostRecentDraft.number + 1 : 1
    const input = {
      number: draftNumber,
      teacherId: data.shareDraftTeacherId,
      text
    }

    createDraft({ variables: { input } })
  }

  const { loading, error, data } = useQuery(QUERY, {
    variables: {},
    onCompleted: (data) => {
      const { draftFeedback, draftRewrite } = getDraftData(data)

      if (draftFeedback && !draftRewrite)
        history.replace('/draft/')
    }
  })

  const [createDraft, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_DRAFT, {
    onCompleted: () => history.push('/draft/submitted/')
  })

  let initialText
  let mostRecentDraft

  if (data)
    mostRecentDraft = data.mostRecentDraft

  if (mostRecentDraft) {
    if (mostRecentDraft.draftfeedback && mostRecentDraft.draftfeedback.draftrewrite) {
      initialText = mostRecentDraft.draftfeedback.draftrewrite.text
    } else {
      initialText = mostRecentDraft.text
    }
  }

  return (
    <MainLayout className='WriteDraft width-900'>
      {loading &&
        <LinearProgress indeterminate />
      }

      {error &&
        <p className='error'>Failed to fetch data</p>
      }

      {!loading && !error &&
        <Editor
          initialText={initialText}
          prompt={data.currentPrompt}
          onSubmit={handleSubmitDraft}
          disabled={mutationLoading}
        />
      }

      {mutationError &&
        <Snackbar
          message='Failed to submit writing'
          actionText='Dismiss'
          timeoutMs={5000}
        />
      }
    </MainLayout>
  )
}

export default WriteView