import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import ShareDraft from './ShareDraft'
import { Snackbar } from '@material/react-snackbar'

const UPDATE_DRAFT = gql`
  mutation UpdateDraft($input: UpdateDraftInput!) {
    updateDraft(input: $input) {
      draft {
        id
        teacher {
          id
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`

const ShareDraftDialog = ({ draftId, onClose, open }) => {
  const [snackbarText, setSnackbarText] = useState(null)

  const handleSelectTeacher = (teacherId) => {
    if (teacherId) {
      setSnackbarText(null)
      updateDraft({ variables: { input: { draftId, teacherId } } })
    } else {
      onClose('close')
    }
  }

  const [updateDraft, { loading }] = useMutation(UPDATE_DRAFT, {
    onCompleted: () => {
      onClose('shareDraftSuccess')
      setSnackbarText('Successfully shared draft')
    },
    onError: () => setSnackbarText('Failed to share draft')
  })

  const closeAction = loading ? '' : 'close'

  return (
    <div className='ShareDraftDialog'>
      <Dialog
        open={open}
        escapeKeyAction={closeAction}
        scrimClickAction={closeAction}
        onClose={onClose}
      >
        <DialogTitle>
          Share Draft
        </DialogTitle>
        <DialogContent>
          <ShareDraft onSelectTeacher={handleSelectTeacher} />
        </DialogContent>
        <DialogFooter>
          <DialogButton action='cancel' disabled={loading}>
            Cancel
          </DialogButton>
        </DialogFooter>
      </Dialog>

      {snackbarText &&
        <Snackbar
          message={snackbarText}
          actionText='Dismiss'
          timeoutMS={5000}
          onClose={() => setSnackbarText(null)}
        />
      }
    </div>
  )
}

ShareDraftDialog.propTypes = {
  draftId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ShareDraftDialog