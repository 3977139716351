import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useInterval } from '../hooks'

const Timer = ({ onEnd, onTick, pause, seconds: secondsProp, showSecondsOnly }) => {
  const [delay, setDelay] = useState(null)
  const [startedAt, setStartedAt] = useState(null)
  const [timeRemaining, setTimeRemaining] = useState(secondsProp)
  const [totalSeconds, setTotalSeconds] = useState(secondsProp)

  useEffect(() => {
    setStartedAt(moment())
  }, [])

  useEffect(() => {
    if (pause) {
      setDelay(null)
    } else {
      setDelay(1000)
      setStartedAt(moment())
      setTotalSeconds(timeRemaining)
    }
    // eslint-disable-next-line
  }, [pause])

  useInterval(() => {
    const newTimeRemaining = Math.max(totalSeconds - moment().diff(startedAt, 'seconds'), 0)
    setTimeRemaining(newTimeRemaining)

    if (newTimeRemaining === 0) {
      setDelay(null)
      onEnd()
    }

    if (onTick)
      onTick(newTimeRemaining)
  }, delay)

  const duration = moment.duration(timeRemaining, 'seconds')
  let timer

  if (showSecondsOnly) {
    timer = duration.asSeconds()
  } else {
    const minutes = duration.minutes()
    const seconds = duration.seconds().toString().padStart(2, '0')
    timer = `${minutes}:${seconds}`
  }

  return (
    <div className='Timer'>
      {timer}
    </div>
  )
}

Timer.defaultProps = {
  pause: false,
  showSecondsOnly: false
}

Timer.propTypes = {
  onEnd: PropTypes.func.isRequired,
  onTick: PropTypes.func,
  pause: PropTypes.bool,
  seconds: PropTypes.number.isRequired,
  showSecondsOnly: PropTypes.bool
}

export default Timer