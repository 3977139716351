import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material/react-icon-button'
import LinearProgress from '@material/react-linear-progress'
import MaterialIcon from '@material/react-material-icon'
import classNames from 'classnames'
import { Document, Page, pdfjs } from 'react-pdf'

import './PDFViewer.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const BlankDocument = ({ error, isPage, loading }) => (
  <div className={classNames('BlankDocument', { page: isPage })}>
    {error &&
      <p className='error'>Failed to load PDF file</p>
    }

    {loading &&
      <LinearProgress indeterminate />
    }
  </div>
)

const PDFViewer = ({ file, onLoad }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNum, setPageNum] = useState(1)

  useEffect(() => {
    setNumPages(null)
    setPageNum(1)
  }, [file])

  const handleDocumentLoaded = ({ numPages }) => {
    setNumPages(numPages)

    if (onLoad)
      onLoad()
  }

  return (
    <div className='PDFViewer'>
      <Document
        className='document'
        file={file}
        error={<BlankDocument error />}
        loading={<BlankDocument loading />}
        onLoadSuccess={handleDocumentLoaded}
      >
        <Page
          className='page'
          pageNumber={pageNum}
          error={<BlankDocument error isPage />}
          loading={<BlankDocument loading isPage />}
        />
      </Document>

      {numPages &&
        <nav>
          <IconButton
            onClick={() => setPageNum(pageNum - 1)}
            disabled={pageNum === 1}
          >
            <MaterialIcon icon='keyboard_arrow_left' />
          </IconButton>

          <p>
            Page <span className='standout'>{pageNum}</span> of <span className='standout'>{numPages}</span>
          </p>

          <IconButton
            onClick={() => setPageNum(pageNum + 1)}
            disabled={pageNum === numPages}
          >
            <MaterialIcon icon='keyboard_arrow_right' />
          </IconButton>
        </nav>
      }
    </div>
  )
}

PDFViewer.propTypes = {
  file: PropTypes.string.isRequired,
  onLoad: PropTypes.func
}

export default PDFViewer