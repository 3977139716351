import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog'
import LinearProgress from '@material/react-linear-progress'
import classNames from 'classnames'
import moment from 'moment'
import { Snackbar } from '@material/react-snackbar'
import { gql } from 'apollo-boost'
import { orderBy } from 'lodash/collection'
import { useQuery, useMutation } from '@apollo/react-hooks'

import './SchedulePromptDialog.scss'
import { secondsToText } from '../lib/util'

const PROMPTS = gql`
  {
    prompts {
      id
      text
      responseTime
      created
    }
  }
`

const CREATE_PROMPT_INSTANCE = gql`
  mutation CreatePromptInstance($input: CreatePromptInstanceInput!) {
    createPromptInstance(input: $input) {
      promptInstance {
        id
        startDate
        endDate
        hasDrafts
        prompt {
          id
          text
          responseTime
        }
      }
    }
  }
`

const UPDATE_PROMPT_INSTANCE = gql`
  mutation UpdatePromptInstance($input: UpdatePromptInstanceInput!) {
    updatePromptInstance(input: $input) {
      promptInstance {
        id
        prompt {
          id
          text
          responseTime
        }
      }
    }
  }
`

const SchedulePromptDialog = ({ onClose, open, selectedWeek }) => {
  const [prompts, setPrompts] = useState([])
  const [promptIndex, setPromptIndex] = useState(null)
  const [snackbarText, setSnackbarText] = useState(null)

  const [createPromptInstance, { loading: createPILoading }] = useMutation(CREATE_PROMPT_INSTANCE, {
    onCompleted: () => {
      setSnackbarText('Successfully scheduled prompt')
      onClose('createPromptInstanceSuccess')
    },
    onError: () => {
      setSnackbarText('Failed to schedule prompt')
    }
  })

  const [updatePromptInstance, { loading: updatePILoading }] = useMutation(UPDATE_PROMPT_INSTANCE, {
    onCompleted: () => {
      setSnackbarText('Successfully updated scheduled prompt')
      onClose('updatePromptInstanceSuccess')
    },
    onError: () => {
      setSnackbarText('Failed update scheduled prompt')
    }
  })

  const disabled = createPILoading || updatePILoading

  useEffect(() => {
    const { promptInstance } = selectedWeek

    if (open && promptInstance) {
      prompts.forEach((prompt, index) => {
        if (prompt.id === promptInstance.prompt.id)
          setPromptIndex(index)
      })
    } else {
      setPromptIndex(null)
    }
  }, [open, prompts, selectedWeek])

  const handleClose = (action) => {
    setPromptIndex(null)
    onClose(action)
  }

  const handlePromptClick = (index) => {
    if (!disabled)
      setPromptIndex(index)
  }

  const handleSchedulePrompt = () => {
    const promptId = prompts[promptIndex].id

    if (selectedWeek.promptInstance) {
      updatePromptInstance({
        variables: {
          input: {
            promptInstanceId: selectedWeek.promptInstance.id,
            promptId
          }
        }
      })
    } else {
      const startDate = moment(selectedWeek.startDate).format()
      const endDate = moment(selectedWeek.endDate).endOf('day').format()

      createPromptInstance({
        variables: {
          input: { promptId, startDate, endDate }
        }
      })
    }
  }

  const { loading, error } = useQuery(PROMPTS, {
    variables: {},
    onCompleted: (data) => {
      setPrompts(orderBy(data.prompts, 'created', 'desc'))
    }
  })

  const closeAction = disabled ? '' : 'close'
  const format = 'MMM D'

  return (
    <div className='SchedulePromptDialog'>
      <Dialog
        open={open}
        onClose={handleClose}
        escapeKeyAction={closeAction}
        scrimClickAction={closeAction}
      >
        <DialogTitle>
          Schedule Prompt
        </DialogTitle>
        <DialogContent>
          <p>
            Week: {moment(selectedWeek.startDate).format(format)} &ndash; {moment(selectedWeek.endDate).format(format)}
          </p>

          {loading &&
            <LinearProgress indeterminate />
          }

          {error &&
            <p className='error'>Failed to load prompts</p>
          }

          <div>
            {prompts.map((prompt, index) => {
              const promptGroupClass = classNames('prompt-group bottom-16', {
                selected: index === promptIndex
              })

              return (
                <div className={promptGroupClass} onClick={() => handlePromptClick(index)} key={prompt.id}>
                  <p className='text'>
                    {prompt.text}
                  </p>
                  <p className='info'>
                    Created {moment(prompt.created).format('L')}
                    <span className='separator'>&bull;</span>
                    {secondsToText(prompt.responseTime)}
                  </p>
                </div>
              )
            })}
          </div>
        </DialogContent>
        <DialogFooter>
          <DialogButton action='cancel' disabled={disabled}>
            Cancel
          </DialogButton>
          <DialogButton
            action=''
            onClick={handleSchedulePrompt}
            disabled={disabled || promptIndex === null}
          >
            {selectedWeek.promptInstance ? 'Change' : 'Schedule'}
          </DialogButton>
        </DialogFooter>
      </Dialog>

      {snackbarText &&
        <Snackbar
          message={snackbarText}
          actionText='Dismiss'
          onClose={() => setSnackbarText(null)}
          timeoutMS={5000}
        />
      }
    </div>
  )
}

SchedulePromptDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedWeek: PropTypes.shape({
    endDate: PropTypes.object.isRequired,
    promptInstance: PropTypes.object,
    startDate: PropTypes.object.isRequired
  }).isRequired
}

export default SchedulePromptDialog